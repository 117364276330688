import React,{useEffect,useState} from "react"
import PropTypes from "prop-types"
import { Layout, Menu, Badge, Row, Divider } from "antd"
import { LinkedinOutlined,FacebookOutlined,InstagramOutlined,YoutubeOutlined } from "@ant-design/icons";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MuiLink  from "@material-ui/core/Typography"
import PersonOutline from '@material-ui/icons/PersonOutline';
import TEAMLINKLogo from '../assets/icons/TeamlinkLogoWhite.svg'
// import {ReactComponent as Logo}  from '../assets/icons/TeamlinkLogoWhite.svg'
import TEAMLINKBlackLogo from '../assets/icons/TeamlinkLogoBlack.svg'
import {compose} from 'recompose'
import {inject,observer} from 'mobx-react'
import LoginIconButton from '../components/common/LoginIconButton';
import UserAvatarButton from '../components/common/UserAvatarButton'
import {hBox,vBox} from "gx-web-ui/utils/flex";
import MePanel from '../components/setting/MePanel';
import MessageMenu from "./message/MessageMenu";
import LandingPageHeaderTab from '../components/common/LandingPageHeaderTab'
import Sticky from 'react-sticky-el';
import { useStaticQuery, graphql, Link ,navigate} from "gatsby"
import trim from 'lodash/trim'
import BusinessMenu from '../components/common/BusinessMenu'
import QRMenu from "./common/QRMenu";

const { Header, Footer, Content, Sider } = Layout

const Container = (props) => {

const { defKey, children, setLoginModalDisplay,appStore, uiStore } = props

const [scrolling, setScrolling] = useState(false);
const [scrollTop, setScrollTop] = useState(0);
const [visible, setVisible] = useState(false);
const [ heartBeat, setHeartBeat ] = React.useState({ numNewMessages: 0 })

const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
    setScrolling(e.target.documentElement.scrollTop > scrollTop);
}

const {loggedInUser, heartBeatFlag} = appStore

const {switchMeSetting, setOpenLogin,isMeSettingOpen, checkLogin} = uiStore

useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);    

  React.useEffect(() => {
        let timeoutHandle;

        const timeoutFn = () => {            
            
            if (appStore.loggedInUser) {
                appStore.clientApi.heartBeat_().then((hb) => {
                    setHeartBeat({ numNewMessages: hb.numUnreadNoti + hb.numUnreadCs + hb.numUnreadMsg });
                    //console.log('>>>message count:', hb.numUnreadNoti + hb.numUnreadCs + hb.numUnreadMsg)

                    timeoutHandle = setTimeout(timeoutFn, 30000);
                });          
            } else {
                setHeartBeat({numNewMessages: 0});
            }  
        };

        timeoutFn();

        return () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
        };
    }, [loggedInUser, heartBeatFlag]);


const getCurrentPath = ()=>{
    let myPath = window.location.pathname
    return trim(myPath,`/`)
}
// const data = useStaticQuery(graphql`
//     query SiteTitleQuery {
//         site {
//         siteMetadata {
//             title
//         }
//         }
//     }
// `)

    const tabs=[
        {
            label:'Explore',
            onClick:()=>{
                if(!['explore','collections'].includes(getCurrentPath().toLowerCase())){
                    navigate('/explore');
                }
            },
            // isSelected:isExploreView
        },
        {
            label:'Collections',
            onClick:checkLogin(()=>{
                if(!['explore','collections'].includes(getCurrentPath().toLowerCase())){
                    navigate('/collections');
                }
            }),
            // isSelected:isCollectionView
        },
        {
            label: 'Messages',
            onClick : loggedInUser ? ()=>{setVisible(!visible)}  :  ()=>{setOpenLogin(true)},
            // isSelected:isMessagesView,
            className:'ignore-class'
        },
    ]

  let headerColor = "rebeccapurple"
  return (
    <Layout style={{
        height : '100%',
        background : 'white'

    }}>
        <Header
            style={{
                position: "fixed",
                zIndex: 2,
                width: "100%",
                // maxWidth : 1440,
                height : scrollTop ? 64 : 100,
                background: scrollTop ? "white" : "linear-gradient(rgb(0,0,0,0.4),rgb(0,0,0,0))" ,
                padding : 0
            //   backgroundColor: headerColor,
            }}
        >
            <div style={{
                padding : '0px 80px 0px 80px', width : "100%", margin: "0 auto",
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
            }}> 
                <Link style={{...hBox('center')}}  to="https://www.teamlink.com.au">
                    <img src={ scrollTop ? TEAMLINKBlackLogo : TEAMLINKLogo} style={{'height' : 28, 'width' : 'auto'}}/>
                    {/* <Img fixed={data.file.childImageSharp.fixed}/> */}
                </Link>
                <div style={{...hBox('center')}}>
                    {tabs.map((item, index)=> index < 2 ?
                        <LandingPageHeaderTab {...item} key={item.label} isScrollTop={scrollTop} style={{marginLeft:24,marginRight:24}}/> :
                        <Badge size={'small'} key={item.label} offset={[-42, 5]} count={heartBeat.numNewMessages}>
                         <LandingPageHeaderTab {...item} key={item.label} isScrollTop={scrollTop} style={{marginLeft:24,marginRight:48, marginTop: -2}}/>
                        </Badge>
                    )}
                    <QRMenu textColor={scrollTop ? 'rgb(0,0,0,0.4)' : 'white'} />
                    {loggedInUser?
                    <UserAvatarButton onClick={()=>switchMeSetting()} avatar={loggedInUser.avatar} />
                    // <Avatar onClick={()=>setIsMeSettingOpen(true)} style={{fontSize:16,marginLeft:16}} className={styles.avatar} src={loggedInUser.avatar}>{!loggedInUser.avatar && loggedInUser.name}</Avatar>
                    :
                    <BusinessMenu textColor={scrollTop ? 'rgb(0,0,0,0.4)' : 'white'} onClientLogin={()=>setOpenLogin(true)} />
                    // <>
                    //     <LoginIconButton scrollTop={scrollTop} onClick={()=>setOpenLogin(true)}/>
                    // </>
                    // <Button variant={'outlined'} color='primary' onClick={()=>setOpenLogin(true)}>
                    //     <Account height={12} width={12} fill={'black'} style={{marginRight:4}}/>
                    //     Login
                    // </Button>
                    }
                </div>
               
            </div>
            <Sticky topOffset={-64} stickyStyle={{ transform: "translateY(64px)" }}>
                <MessageMenu visible={visible} setVisible={setVisible} style={{right:-80}}/>
            </Sticky>
            {/* <div style={{  max-width : 1200, padding : '0px 80px 0px 80px', width : "100%", margin: "0 auto" }}>
                <Link to="/">
                    <img src={ scrollTop ? TEAMLINKBlackLogo : TEAMLINKLogo} style={{'height' : 28, 'width' : 'auto'}}/>

                    <Logo  height={28} width={120} fill={ scrollTop ? 'black' : 'white'} /> 
                </Link>


                <Menu
                    style={{ background:"transparent ", float: "right"}}
                    // theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={defKey}
                >
                <Menu.Item key="1">
                    <Link  style={{color : scrollTop ? 'rgb(0,0,0,0.4)' : 'white' , fontWeight : 700}} to="/explore/">Explore</Link>
                </Menu.Item>
                <Menu.Item key="2">
                    <div>
                        <Link style={{color : scrollTop ? 'rgb(0,0,0,0.4)' : 'white', fontWeight : 700 }} to="/collections/">Collections</Link>
                    </div>
                    
                </Menu.Item>
                <Menu.Item key="3">
                    {
                        !loggedInUser ? 
                        <a style={{color : scrollTop ? 'rgb(0,0,0,0.4)' : 'white' }} onClick={()=>setOpenLogin(true)}>Messages</a> :
                        <a style={{color : scrollTop ? 'rgb(0,0,0,0.4)' : 'white' }} onClick={()=>setVisible(!visible)} className={'ignore-class'}>Messages</a>
                    }
                </Menu.Item>
                    {
                        !loggedInUser ? 
                        <LoginIconButton scrollTop={scrollTop} onClick={()=>setOpenLogin(true)}/>
                        :
                        <UserAvatarButton onClick={()=>switchMeSetting()} avatar={loggedInUser.avatar} isLandingPage={true}/>
                    }
                </Menu>
                <Sticky topOffset={-64} stickyStyle={{ transform: "translateY(64px)" }}>
                    <MessageMenu visible={visible} setVisible={setVisible} style={{right:-80}}/>
                </Sticky>
            </div> */}
        </Header>
        <Layout style={{ background : 'white' }}>
            <Content
                style={{
                    // maxWidth : 1440,
                    width : '100%'
                    // marginTop: 64,
                    // minHeight: "100vh", //edit this to change minimum page height
                }}
            >
                <div style={{ /*maxWidth: "900px",*/width : "100%", margin: "0 auto" }}>{children}</div>
            </Content>
            <Sider style={{ position: 'fixed', right: 0, top: 64, zIndex: 99 }} >
                <MePanel />
            </Sider>
        </Layout>
        <Footer style={{ ...hBox('center','center'), background :'#0D0E10', width : "100%", padding: 14 }}>
            <Row style={{ maxWidth: 1315, width: '100%', paddingLeft: 20, paddingRight: 20 }} justify="space-between" align="middle">
                <Typography style={{'color' : 'white', margin: 0}} variant="body2" >© {new Date().getFullYear()} All rights reserved Teamlink</Typography>
                {/* <Typography style={{'color' : 'white',marginRight : 12,marginLeft : 12}} variant="body2"> | </Typography> */}

                <Row>
                    <a style={{ marginLeft: 20, color: '#fff' }} href="https://www.linkedin.com/company/teamlink-com-au/" target="_blank"><LinkedinOutlined /></a>
                    <a style={{ marginLeft: 20, color: '#fff' }} href="https://www.facebook.com/Teamlinkcom-111821207947941" target="_blank"><FacebookOutlined /></a>
                    <a style={{ marginLeft: 20, color: '#fff' }} href="https://www.instagram.com/teamlink.au/" target="_blank"><InstagramOutlined /></a>
                    <a style={{ marginLeft: 20, color: '#fff' }} href="https://www.youtube.com/channel/UCThD0icL7m2-kxcAz_1mVEw" target="_blank"><YoutubeOutlined /></a>
                </Row>
            </Row>
        </Footer>
    </Layout>
  )
}
Container.propTypes = {
    children: PropTypes.node.isRequired,
}


export default compose(inject('appStore','uiStore'),observer)(Container)
