import React, { Suspense, useState, useLayoutEffect ,useRef ,useEffect} from "react";
import Container from "../components/container"
import Image from "../components/image"
import _ from 'lodash'
import SEO from "../components/seo"
import {compose} from 'recompose'
import {inject,observer} from 'mobx-react'
import {vBox,hBox} from "gx-web-ui/utils/flex";
import LoginModal from '../components/LoginModal'
import VerificationModal from '../components/VerificationModal'
import LandingPageSearchComponent from '../components/LandingPageSearchComponent'
import LandingPagePropertyTabPanel from '../components/LandingPagePropertyTabPanel'
import LandingPageOptions from '../components/LandingPageOptions'
import MePanel from '../components/setting/MePanel'
import LandingPageMePanel from '../components/setting/LandingPageMePanel'

import backGroundImage from '../assets/images/Cover-img.jpeg'
import MobileBackGroundImage from '../assets/images/Mobile-BG.png'
import GooglePlayDownloadBtn from '../components/common/GooglePlayDownloadBtn'
import AppStoreDownloadBtn from '../components/common/AppStoreDownloadBtn'
import Typography from '@material-ui/core/Typography';
import useParentComponentDimension from '../utils/parentComponentDimension'
import LandingPageFilterSearch from '../components/LandingPageFilterSearch'
// import ReactDependentScript from 'react-dependent-script';

import loadGMaps from '../utils/loadGMaps'
import withRoot from '../withRoot'


const IndexPage = (props) => {
    const {appStore, uiStore} = props

    const {userLocation, setUserLocation} = appStore

    const {openLogin,setOpenLogin,isVerificationOpen,setIsVerificationOpen,isMeSettingOpen} = uiStore


    const [dimensions, setDimensions] = useState({ width:1440, height: 0 });


    const targetRef = useRef();

    // const dimensions = useParentComponentDimension(targetRef)
    
    
    useLayoutEffect(() => {
        if (targetRef.current) {
          setDimensions({
            width: targetRef.current.offsetWidth,
            height: targetRef.current.offsetHeight
          });
        }
      }, []);



    // console.log('backgroundwidth',dimensions.width)

    const settingRef = useRef(null)

    useEffect(() => {
        if(_.isNull(userLocation)){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    setUserLocation({
                        "latitude" : position.coords.latitude,
                        "longitude" : position.coords.longitude
                    })
                });
            } else {
                setUserLocation({
                    "latitude": -33.8688,
                    "longitude": 151.2093
                })
            }

            
        }else{
            console.log(userLocation)
        }
    }, [userLocation])

    
    

    

    return <Container >
        <SEO title="Home" />
            <div style={{position:'relative'}}>
                {/* <MePanel parentDivWidth={dimensions.width}/> */}

                <div ref={targetRef} style={{...vBox('center'),width : '100%'}}> 
                    <div  style={{...hBox('center','center'), backgroundImage : `url(${backGroundImage})`,backgroundRepeat : 'no-repeat',backgroundPosition:'center',backgroundSize: 'cover',width : "100%",height : 600}}>
                        <LandingPageSearchComponent parentDivWidth={dimensions.width}/>
                    </div>

                    <LandingPagePropertyTabPanel  parentDivWidth={dimensions.width}/>
                    <div style={{ ...vBox('start','center'),backgroundImage : `url(${MobileBackGroundImage})`,backgroundRepeat : 'no-repeat',backgroundPosition:'center',backgroundSize: 'cover',width : "100%",height : `calc(${dimensions.width}px / 2.41)`,paddingLeft : 180}}>
                        {/* <Typography variant='h4' style={{color : 'white',fontWeight : 600}}>Properties in your hand</Typography>
                        <Typography variant='h6' style={{paddingTop: 10, color : 'white', fontWeight: 400, width : 550,fontSize: 16 }}>Teamlink is leading real estate platform dedicated to empowering consumers with data and knowledge, and connecting them with the best Australia local agents. </Typography>
                        <div style = {{...hBox('center','start'), marginTop : 20}}>
                            <AppStoreDownloadBtn style={{background : 'white',opacity : '0.8', width : 260}} 
                            onClick={
                                ()=>window.open('https://apps.apple.com/us/app/teamlink-com-au/id1495499958?ls=1', '_blank')} 
                            />
                            <GooglePlayDownloadBtn style={{background : 'white',opacity : '0.8', width : 260, marginLeft : 24}} 
                            onClick={
                                ()=>window.open('https://play.google.com/store/apps/details?id=tech.genx.teamlink', '_blank')
                            } />
                        </div> */}
                    </div>
                    <div style={{width : '100%', padding : '0px 0px 0px 0px'}}>
                        <LandingPageOptions/>
                    </div>
                </div>
                
            </div>
            <LoginModal visible={openLogin} handleCancel={()=>setOpenLogin(false)}   />
            <VerificationModal visible={isVerificationOpen} handleCancel={()=>setIsVerificationOpen(false)} />
            <LandingPageFilterSearch />
        </Container>
}

export default compose(withRoot,inject('appStore','uiStore'),observer)(IndexPage)
