import React,{ useState,useEffect} from 'react'
// import compose from "recompose/compose";
import {hBox} from "gx-web-ui/utils/flex";
import { CaretRightOutlined } from '@ant-design/icons';
import "../assets/styles/antTab.less"
import LandingPageSearchBar from './LandingPageSearchBar'
// import GoogleMapReact from 'google-map-react';
import {inject,observer} from 'mobx-react'
import compose from 'recompose/compose'


import _ from 'lodash'

import { Tabs, Radio } from 'antd';

const { TabPane } = Tabs;

const tabTextBoxShadow = {boxShadow : '1px 1px 6px 0 rgba(0, 0, 0, 0.3)'}

const tabButtonStyle = {borderRadius:'0 4px 4px 4px', boxShadow : '1px 1px 6px 0 rgba(0, 0, 0, 0.2)', height:72, paddingTop:3}

const LandingPageSearchComponent = (props) => {

    const {parentDivWidth, uiStore} = props

    const {setApiLoaded,apiLoaded} = uiStore

    const [activeKey, setActiveKey] = useState("0")

    const onChange = (actKey) =>{
        setActiveKey(actKey)
    }

    return(
        <div className="card-container" style = {{width : 1000,  padding : parentDivWidth >= 1440 ? 0 : '0px 48px 0px 48px'}} >
            
                <Tabs defaultActiveKey="0" type="card" size="default" animated='false' tabBarGutter={14} onChange={onChange}
                    // renderTabBar={()=><div style={{'backgroundColor' : 'black'}}>haha</div>}
                >
                    <TabPane tab={ <span style={{ color :  activeKey==='0' ?  'black' : 'white', background : 'transparent' ,fontSize : 16}} >Buy new</span >} key="0" style = {tabButtonStyle}>
                        <LandingPageSearchBar type={'buyNew'} />
                    </TabPane>
                    <TabPane tab={ <span style={{ color :  activeKey==='1' ?  'black' : 'white', background : 'transparent' ,fontSize : 16}} >Buy existing</span >} key="1" style = {tabButtonStyle}>
                        <LandingPageSearchBar type={'buyExisting'} />
                    </TabPane>
                    <TabPane tab={ <span style={{ color :  activeKey==='2' ?  'black' : 'white', background : 'transparent' ,fontSize : 16}} >Rent</span >} key="2" style = {tabButtonStyle}>
                        <LandingPageSearchBar type={'rent'} />
                    </TabPane>
                </Tabs> 
        </div>
        
    )
}
export default compose(
    inject('filterStore','uiStore'),
    observer
)(LandingPageSearchComponent)